/* eslint-disable arrow-body-style */
import axios from 'axios'

const url = process.env.VUE_APP_API_ROOT_URL

export default () => {
  return axios.create({
    baseURL: url,
  })
}
