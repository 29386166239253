/* eslint-disable import/prefer-default-export */

const PRODUCT_INFO_V1 = 'v1/product/query'
const PRODUCT_INFO_V2 = 'v2/product/query'
const MACHINE_CONFIG = 'v2/config/machine'
const MACHINE_CONFIG_2 = 'v2_1/config/machine'
const PRODUCT_CERTIFICATE = 'v2/product/certificate'
const PRODUCT_CERTIFICATE_VIEW = 'v2/product/viewcertificate'

export {
  PRODUCT_INFO_V1,
  PRODUCT_INFO_V2,
  MACHINE_CONFIG,
  MACHINE_CONFIG_2,
  PRODUCT_CERTIFICATE,
  PRODUCT_CERTIFICATE_VIEW,
}
